.ContainerFixed {
    position: fixed;
    z-index: 1000;
    padding-top: 30px;
    opacity: 1;
    backdrop-filter: blur(20px);
    background: rgba(255,255,255,.3);
    width: 100%;
    height: 80px;
    margin: 0 auto;
}

.fixedlangTel {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin-right: 50px;
}
.langTel {
    position: relative;
    top: 0;
    right: 50px;
    display: flex;
    justify-content: flex-end;
    padding-top: 25px;
}

/* class Tel start */
.tel {
    margin-right: 15px;
    border-right: 2px solid rgb(11, 11, 113);
}
.tel a:hover {
    color: #024470;
}
.tel a {
    font-size: 14px;
    font-weight: 600;
    color: #000000;
}
.tel a span {
    font-size: 22px;
    padding-right: 18px;
}
.telFooter a span {
    font-size: 15px;
    padding-left: 5px;
}
/* class Tel end */

/* class Submit start */
.reg_btn {
   position: relative;
   top: 0;
   left: 40px;
   background-color: #fff;
   color: #000;
   width: 200px;
   display: block;
   padding: 5px;
   margin-top: 10px;
   border: 1px solid #0099FF;
   font-size: 15px;
   font-weight: 600;
   border-radius: 10px;
   z-index: 1;
   box-shadow: 1px 1px 3px #0099FF,
               -1px -1px 3px #0099FF,
               -1px 1px 3px #0099FF,
               1px -1px 3px #0099FF;
}
.reg_btn:focus {
   background-color: #ccc;
}
/* class Submit end */