.navBtn {
    /* width: 1200px; */
    flex-basis: 80%;
    display: flex;
    justify-content: space-between;
}
nav ul {
    width: 930px;
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin-left: 150px;
}
header ul li a {
    font-weight: 600;
    color: #000000;
    font-size: 25px;
    /* text-transform: uppercase; */
}
header ul li a:hover {
    border-bottom: 3px solid #0099FF;
}
.closed {
    display: none;
}
