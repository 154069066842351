.choose {
    width: 1000px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 158px auto 0;
}
.fix_1, .fix_2, .fix_3 {
    width: 308px;
    height: 229px;
    background-color: #ffffff;
}
.fix_1 img, .fix_2 img, .fix_3 img  {
    width: 111px;
    height: 111px;
    position: relative;
    top: -60px;
    left: 0;
}
.choose h4 {
    margin: -10px 35px 15px 35px;
    font-size: 20px;
    color: #333333;
}
.choose p {
    margin-left: 60px;
    width: 194px;
    color: #999999;
    font-size: 18px;
    font-weight: 500;
}