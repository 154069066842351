.price{
	font-size: 18px;
	background: #ffffff;
}

.other {
	text-align: center;
	padding-top: 30px;
	padding-bottom: 30px;
	color: black;
	}
	
.price table{
	border: 0.1rem dotted gray;
	border-radius: 5px;
    margin: 0 auto;
	width: 75%;
	margin-bottom: 20px;
}
.thleft {
	text-align: left;
}
.price table tr th{
	width: 80%;
	border: 0.1rem dotted gray;
}
.price table tr td{
	border: 0.1rem dotted gray;
}
tr:nth-child(1) {
	background-color: rgb(194, 194, 247);
}
tr:nth-child(2n + 3) {
	background-color: #e3e3ff;
}
tr:nth-child(even) {
	background-color: #fffdfd;
}
.price table .center{
text-align: center;
vertical-align: middle;
}
.nofindresurs {
	margin: 20px auto;
	text-align: center;
}
.error404 p {
	text-align: center;
}
/* contacts */
.mainContacts {
	margin-bottom: 40px;
}
.mainContacts h2 {
	text-align: center;
	margin: 50px auto;
}
.mainContacts p {
	font-size: 18px;
	width: 800px;
	text-indent: 30px;
	text-align: justify;
	margin: 10px auto;
}
.contacts {
	font-size: 20px;
	margin-bottom: 15px;
	padding-left: 20px;
	width: 800px;
	margin: 0 auto;
}
.contacts h3 {
	margin-bottom: 20px;
}
.wrapcontacts .link {
	display: block;
	color: #000000;
	text-indent: 0;
	font-size: 18px;
}
.othercontacts {
	text-align: center;
}
.vibertelegram {
	width: 100px;
	display: flex;
	justify-content: space-between;
	margin: 0 auto;
}
.wrapcontacts {
	margin: 0 auto;
	width: 500px;
}
.contacts span:hover {
	border-bottom: 2px solid rgb(0, 187, 255);
}
.phone {
	padding-left: 80px;
	
}
.mail {
	text-align: center;
}
/* gallary */
.containerPhoto {
	margin: 50px 0;
}
.allphoto {
	width: 830px;
	margin: 0 auto;
	cursor: pointer;
	transition: 0.3s;
}
.allphoto img {
	width: 150px;
	border-radius: 10px;
	border: 1px solid rgb(2, 184, 2);
}
.allphoto .changephoto {
	width: 350px;
	transition: 0.3s;
}
.onerow {
	width: 820px;
	display: flex;
	justify-content: space-around;
	align-items:center;
}
.tworow {
	width: 820px;
	display: flex;
	justify-content: space-around;
	align-items: center;
	margin-top: 20px;
	margin-bottom: 20px;
}
.threerow {
	width: 820px;
	display: flex;
	justify-content: space-around;
	align-items: center;
	margin-top: 20px;
	margin-bottom: 20px;
}
.galaryh2 {
	text-align: center;
	padding-bottom: 50px;
	color: black;
}
.one {
	display: flex;
	width: 400px;
	justify-content: space-between;
	align-items: center;
	
}
.two {
	width: 400px;
	display: flex;
	justify-content: space-around;
	align-items: center;
}


@media screen and (max-width: 900px) {
	.mainContacts p {
		width: 500px;
		font-size: 18px;
	}
	.contacts {
		width: 500px;
	}
	.allphoto {
		width: 630px;
		margin: 0 auto;
		cursor: pointer;
		transition: 0.3s;
	}
	.allphoto img {
		width: 120px;
		border-radius: 10px;
		border: 1px solid rgb(2, 184, 2);
	}
	.allphoto .changephoto {
		width: 250px;
		transition: 0.3s;
	}
	.onerow {
		width: 620px;
		display: flex;
		justify-content: space-around;
		align-items:center;
	}
	.tworow {
		width: 620px;
		display: flex;
		justify-content: space-around;
		align-items: center;
		margin-top: 20px;
		margin-bottom: 20px;
	}
	.threerow {
		width: 620px;
		display: flex;
		justify-content: space-around;
		align-items: center;
		margin-top: 20px;
		margin-bottom: 20px;
	}
}
@media screen and (max-width: 590px) {
	.allphoto {
		cursor: auto;
		width: 250px;
		display: inline;
	}
	.allphoto img {
		width: 250px;
		margin-bottom: 20px;
	}
	.onerow {
		width: 250px;
		display: block;
		margin: 0 auto;
	}
	.tworow{
		width: 250px;
		display: block;
		margin: 0 auto;
	}
	.threerow{
		display: none;
	}
	.allphoto .changephoto {
		width: 230px;
		transition: none;
	}
	.price {
		font-size: 14px;
	}
	.mainContacts p {
		width: 400px;
		font-size: 16px;
	}
	.contacts {
		padding-left: 0;
		width: 300px;
	}
	.contacts p {
		width: 300px;
		padding-left: 0;
	}
	.mainContacts h2 {
		margin: 20px auto;
	}
	.wrapcontacts .link {
		font-size: 16px;
	}
	.phone {
		width: 300px;
		padding-left: 0px;
		text-align: center;
	}
	.wrapcontacts p {
		padding-left: 0;
		margin-left: 0;
		text-align: center;
	}
	.vibertelegram {
		margin-left: 100px;
	}
	.mail {
		width: 300px;
	}
}
@media screen and (max-width: 425px){
	.allphoto {
		cursor: auto;
		width: 230px;
		display: inline;
	}
	.allphoto img {
		width: 230px;
		margin-bottom: 20px;
	}
	.onerow {
		width: 230px;
		display: block;
		margin: 0 auto;
	}
	.tworow{
		width: 230px;
		display: block;
		margin: 0 auto;
	}
	.threerow{
		display: none;
	}
	.allphoto .changephoto {
		width: 230px;
		transition: none;
	}

	.mainContacts p {
		width: 300px;
	}
	.contacts {
		padding-left: 0;
		width: 300px;
	}
}
@media screen and (max-width: 330px) {
	.mainContacts p {
		width: 250px;
		font-size: 13px;
	}
	.contacts {
		padding-left: 0;
		width: 250px;
	}
	.wrapcontacts .link {
		font-size: 13px;
	}
	.phone {
		width: 250px;
	}
	.vibertelegram {
		margin-left: 80px;
	}
	.mail {
		width: 250px;
	}
}
