.active {
    background-color: #eceaea;
}
.quilon, .quilon2 {
    display: block;
    width: 320px;
    margin: 20px auto;
}
.quilon2 {
    width: 370px;
    margin: 5px auto;
}
.furnit2 {
    display: block;
    width: 250px;
    height: 280px;
    margin: 20px auto;
}
.furnitul li {
    list-style: disc;
    list-style-position: inside;
    width: 100%;
}
.window {
    display: block;
	width: 400px;
    margin: 20px auto;
}
.windowraz li {
    list-style: disc;
    list-style-position: inside;
    width: 100%;
}
.regul {
    display: block;
    width: 450px;
    margin: 20px auto;
}
.atricelandcatalog {
    width: 1140px;
    display: flex;
    justify-content: space-between;
    flex-flow: row-reverse;
    margin: 10px auto 20px;
}
.articelquilon {
    width: 800px;
    margin: 20px auto;
}
.articelquilon h2 {
    text-align: center;
    padding: 20px 0;
}
.articelquilon p, .articelquilon ul {
    font-size: 18px;
    text-indent: 30px;
    text-align: justify;
}
.catalogs {
    color: #3e77aa;
    border-right: 1px solid  rgba(62, 119, 170, 0.3);
    width: 250px;
    margin: 20px auto;
}
.catalogs li {
    font-size: 16px;
    font-weight: 500;
    padding: 10px 0 20px 0px;
}
.catalogs h3 {
    padding: 20px 0;
}
.catalogs a {
    color: #3e77aa;
}
.catalogs a:hover {
    color: rgb(230, 100, 100);
  border-bottom: 1px solid rgb(230, 100, 100);;
}
@media screen and (max-width: 1100px) {
    .atricelandcatalog {
        width: 950px;
        display: flex;
        justify-content: space-between;
    }
    .articelquilon {
        width: 600px;
    }
}
@media screen and (max-width: 917px) {
    .atricelandcatalog {
        width: 710px;
        display: flex;
        justify-content: space-between;
    }
    .articelquilon {
        width: 450px;
    }
    .articelquilon p, .articelquilon ul {
        font-size: 14px;
    }
    .catalogs {
        width: 200px;
    }
}
@media screen and (max-width: 700px) {
    .atricelandcatalog {
        width: 500px;
        display: block;
    }
    .articelquilon {
        width: 500px;
    }
    .catalogs {
        width: 250px;
    }
}
@media screen and (max-width: 542px) {
    .atricelandcatalog {
        width: 380px;
        display: block;
    }
    .articelquilon {
        width: 380px;
    }
    .catalogs li {
        width: 100%;
    }
    .window, .regul {
        width: 100%;
    }
}
@media screen and (max-width: 405px) {
    .articelquilon h2 {
        font-size: 22px;
    }
    .atricelandcatalog {
        width: 300px;
        display: block;
    }
    .articelquilon {
        width: 300px;
    }
    .catalogs li {
        width: 100%;
    }
    .quilon, .quilon2 {
        display: block;
        width: 280px;
    }
    .quilon2 {
        width: 310px;
    }
}
@media screen and (max-width: 325px) {
    .articelquilon h2 {
        font-size: 18px;
    }
    .atricelandcatalog {
        width: 250px;
        display: block;
    }
    .articelquilon {
        width: 250px;
    }
    .catalogs li {
        width: 100%;
    }
    .quilon, .quilon2 {
        display: block;
        width: 250px;
        /* margin: 20px auto; */
    }
    .quilon2 {
        width: 290px;
        margin-left: -20px;
    }
}