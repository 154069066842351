.logo {
    position: relative;
    top: -30px;
    left: 80px;
}
.logo img {
    transform: scale(1.4);
}
.logo h3 {
    display: block;
    font-size: 16px;
    width: 150px;
    font-weight: 800;
    background-color: #ffffff;
    color: #5c5874;
    border: 1px solid #0099FF;
    border-radius: 30px;
    padding: 5px 0 5px 17px;
    margin: 0 0 0 -40px;
}