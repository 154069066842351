.telegramtitle {
    position: fixed;
    bottom: 0;
    right: 0;
    scale: 0.9;
    z-index: 20;
}
.telFooter {
    margin-top: 20px;
}
.telFooter a{
    color: #ccc;
}
.telFooter a:hover {
    color: #fff;
}
.socials {
  width: 50px;
  height: 50px;
    color: #ccc;
}
.footer {
    width: 100%;
    height: 40px;
    background-color:  #171616;
    text-align: center;
    color: #686868;
    padding-top: 5px;
}
.closed {
    display: none;
}
@media screen and (max-width: 840px) {
    .telegramtitle {
        position: fixed;
        bottom: 0;
        right: 0;
        scale: 0.8;
        z-index: 20;
    }
}
@media screen and (max-width: 400px) {
    .telegramtitle {
        position: fixed;
        bottom: 0;
        right: 0;
        scale: 0.6;
        z-index: 20;
    }
}