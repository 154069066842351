.enchange {
  position: absolute;
  top: 5px;
  right: 50px;
}

.vectorup {
  transform: rotate(180deg);
}
.en {
  position: absolute;
  top: 60px;
  right: 0px;
  width: 50px;
  height: 40px;
  background-color: rgb(5, 5, 142);
  color: #fff;
  text-align: center;
  border-radius: 5px;
  font-size: 15px;
  z-index: 5;
  border-top: 1px solid#fff;
}
.en a {
  display: inline-block;
  color: #fff;
  width: 100%;
}
.select {
  /* position: absolute;
  top: 5px;
  right: 10px; */
  margin-top: -5px;
  width: 50px;
  height: 38px;
  background-color: rgb(5, 5, 142);
  color: #fff;
  text-align: center;
  border-radius: 5px;
  font-size: 15px;
}

.lang {
margin-top: 2px;
}
.lang span {
  padding-left: 5px;
}